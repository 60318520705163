<template>
  <div class="sign-in">
    <username-selector
        :show-tabs="shouldShowTabs"
        v-model="login"
        :type.once="login_type"
        title=""
        inputWidth="220px"
        @type-changed="onLoginTypeChanged"
    ></username-selector>
    <div class="password" v-if="passwordRequired">
      <a-form-input
        id="form"
        v-model.trim="password"
        :placeholder="$t('shared.placeholders.typePassword')"
        :aria-label="$t('shared.password')"
        :label="$t('shared.password')"
        :show-error="showPasswordValidation && !password.length"
        :error-message="$t('signUp.errors.passwordBlank')"
        required
        type="password"
        @input="onPasswordChange"
        @invalid.prevent="showPasswordValidation = true"
      />
      <slot name="forgot"></slot>
    </div>
    <slot name="submit"></slot>
    <slot name="social"></slot>
  </div>
</template>
<script>
import UsernameSelector from "./AccountSettings/UsernameSelector.vue"

export default {
  name: "SignIn",
  components: {
    UsernameSelector,
  },
  data() {
    return {
      login: "",
      login_type: "email",  // Default
      password: "",
      showPasswordValidation: false,
    }
  },
  props: {
    enablePhoneLogin: {
      type: Boolean,
      default: false,
    },
    /**
     * Why not use `emit` on the parent component?
     * Because the parent component is a Rails form,
     * and we need to set the value of the inputs
     * from this component.
     */
    inputLogin: {
      type: String,
      required: true,
    },
    inputPassword: {
      type: String,
      required: true,
    }
  },
  computed: {
    shouldShowTabs() {
      return this.enablePhoneLogin;
    },
    passwordRequired() {
      return this.login_type === "email";
    },
  },
  watch: {
    login: function (newVal) {
      this.setInputValue(this.inputLogin, newVal)
    },
    password: function (newVal) {
      this.setInputValue(this.inputPassword, newVal)
    },
  },
  methods: {
    onLoginTypeChanged(type) {
      this.login_type = type;
      this.$emit('type-changed', type);
    },
    onPasswordChange(password) {
      this.password = password;
      this.$emit('password-changed', password);
    },
    setInputValue(id, value) {
      const input = document.querySelector(`#${id}`)
      input.value = value
    },
  },
}
</script>

<style lang="scss">
form.button_to {
  background-color: transparent !important;
}
form.button_to > button {
  appearance: none;
  border: 0;
  background-color: transparent !important;
  margin: 0;
  padding: 0;
  width: 100%;
}
</style>
